a {
    text-decoration: none;
}

table a:hover {
    text-decoration: underline;
}
.MuiDataGrid-root .MuiDataGrid-cell {
    white-space: normal !important;
    word-wrap: break-word !important;
}

.MuiDataGrid-columnHeaderTitle {
    white-space: normal !important;
    word-wrap: break-word !important;
    line-height: 2;
}

@font-face {
    font-family: 'PPPangramSans';
    src: local('PPPangramSans'), url(fonts/PPPangramSans-Medium.woff) format('woff');
}

.rdp-button:hover {
    background-color: #f8834d !important
}